<template>
  <SubPageWrapper
    data-shortcut-table
    width="full"
  >
    <ErrorsNotifyModal :id="modalIds.ERRORS_NOTIFY" />
    <ConfigurationModal :id="modalIds.PLANNED_ORDERS_CONFIGURATION" />
    <ExternalConfirmModal :id="modalIds.EXTERNAL_CONFIRM" />
    <SplitBetweenSuppliersModal />

    <template #loader>
      <SlOverlay :show="ordersCreating" />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb @click="handleBack">
        {{ $t(sourceData.locale) }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('PlannedOrders.Ui.:Caption') }}
      </SlBreadcrumb>
    </template>
    <template #filter>
      <SlBadge
        v-if="activeFilterId !== DEFAULT_FILTER_ID"
        :close-tooltip="getTooltip($t('Web.Filters.RemoveFilter'))"
        closable
        @close="handleCloseFilter"
      >
        <template #text>
          <span class="subpage__filter-name">
            {{ $t('Web.Filters.FilteredByNew') }}
          </span>
          <span>
            {{ activeFilterName }}
          </span>
        </template>
      </SlBadge>
    </template>
    <template #actions>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEdited"
        @click="handleDiscardChanges"
      >
        {{ $t('Web.Collections.Discard') }}
      </SlButton>
      <SlButton
        variant="secondary"
        :disabled="!isEdited"
        @click="handleSaveChanges"
      >
        {{ $t('Web.Collections.SaveChanges') }}
      </SlButton>
      <div
        v-tooltip="getTooltip({
          content: $t('Web.PlannedOrders.TooltipBtnCreate'),
          disabled: !$sl_hasAccess($sl_features.createPlannedOrders) || canCreate || !$sl_tabEditable
        })"
      >
        <SlButton
          :disabled="!canCreate || !hasOrders || !$sl_tabEditable || !$sl_hasAccess($sl_features.createPlannedOrders)"
          @click="handleCreate"
        >
          {{ $t('Web.PlannedOrders.BtnCreate') }}
        </SlButton>
      </div>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <div class="subpage__actions-group">
          <SlSearchInput
            :value="search"
            class="w-280"
            @submit="handleSearch"
          />
        </div>
        <div class="subpage__actions-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="openConfigurationModal"
          >
            <template #prepend>
              <icon
                data="@icons/table_configuration.svg"
                class="fill-off size-16"
              />
            </template>
            {{ $t('Web.PlannedOrders.Configuration') }}
          </SlButton>
          <SlDropdown
            :target-label="$t('Main.CustomizeColumnsMenu')"
            placement="bottom-end"
          >
            <template #prepend-inner>
              <icon
                data="@icons/table.svg"
                class="size-16 fill-off"
              />
            </template>
            <template #content>
              <SlDropdownOption
                v-for="option in showColumnsOptions"
                :key="option.key"
              >
                <template #prepend>
                  <SlCheckbox
                    :id="option.key"
                    :value="isVisibleCol(option.key)"
                    @change="(checked) => toggleVisibleCols(option.key, checked)"
                  />
                </template>
                <label :for="option.key">
                  {{ option.name }}
                </label>
              </SlDropdownOption>
            </template>
          </SlDropdown>
          <SlDropdown
            :target-label="$t('Settings.Ui.grpAutoExport')"
            placement="bottom-end"
          >
            <template #content>
              <SlDropdownOption
                v-for="option in exportOptions"
                :key="option.value"
                select-close
                @select="handleExport(option.value)"
              >
                {{ option.label }}
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </div>
      </div>
      <div
        v-if="tabs.length > 1"
        class="subpage__tabs"
      >
        <SlTabList
          v-model="tabModel"
          :tabs="tabs"
          horizontal
        />
      </div>
      <div class="subpage__table-wrapper">
        <SlTabContent
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <div
            v-if="tableMetaInfo"
            class="meta-info"
          >
            <template v-for="(item, i) in tableMetaInfo">
              <div
                v-if="item.label && item.value"
                :key="i"
                class="meta-info__item"
              >
                <div class="meta-info__label">
                  {{ item.label }}:
                </div>
                <div class="meta-info__value">
                  {{ item.value }}
                </div>
              </div>
            </template>
          </div>
          <div class="subpage__table">
            <SlTable
              :id="tab.value"
              :ref="tab.value"
              :headers="tableHeaders"
              :rows="tableRows"
              unique-key="id"
              header-unique-key="key"
              header-name-key="name"
              header-type-key="type"
              :hidden-columns-keys="['id', checkboxKey, 'ACTIONS', 'ENUMERATION']"
              :max-height="1000"
              :value-parser="parseValue"
              api-sortable
              :sort-by="colClass"
              :sort-order="sortOrder"
              selectable
              pagination
              :total-rows-count="totalRowsCount"
              :total-selected="totalSelected"
              :current-page="currentPage"
              :per-page="perPage"
              :loading="loading"
              :inner-no-data="!!search"
              num-col-key="ENUMERATION"
              num-col
              highlight-row
              @page-change="handlePaginationChange"
              @per-page-change="handlePerPageChange"
              @sort="handleSort"
              @contextmenu:clipboard="handleContextMenu"
            >
              <template #loader>
                <SlOverlay :show="loading" />
              </template>

              <template #header-checkbox>
                <SlCheckbox
                  :value="headerCheckboxValue"
                  :indeterminate="isIndeterminate"
                  :disabled="!$sl_tabEditable"
                  @change="handleSelectRowsAll"
                />
              </template>
              <template #header-ACTIONS-last />

              <template #row-checkbox="slotScope">
                <SlCheckbox
                  v-if="!isNestedRow(slotScope.row)"
                  :value="isRowSelected(slotScope.row)"
                  :disabled="!$sl_tabEditable"
                  @change="(val) => handleSelectRows(val, slotScope)"
                />
              </template>
              <template #SUPPLIER="slotScope">
                <div class="sl-table__cell-text sl-table__cell-text--center">
                  <icon
                    v-if="slotScope.cellValue.expandable"
                    data="@icons/chevron_right.svg"
                    class="fill-off size-16 color-grey-60 mr-6"
                    :class="{
                      'rotate-90': isRowExpanded(slotScope)
                    }"
                    @click="toggleRow(slotScope)"
                  />
                  <template v-if="slotScope.cellValue.expandable">
                    ({{ getInputValue(slotScope) }})
                  </template>
                  <template v-else>
                    {{ getInputValue(slotScope) }}
                  </template>
                </div>
              </template>
              <template #ITEMCODE="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getInputValue(slotScope) }}
                </div>
              </template>
              <template #ITEMDESC="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getInputValue(slotScope) }}
                </div>
              </template>
              <template #LOCATION="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getInputValue(slotScope) }}
                </div>
              </template>
              <template #CONTAINER="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getInputValue(slotScope) }}
                </div>
              </template>
              <template #ORDER_TYPE="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getInputValue(slotScope) }}
                </div>
              </template>
              <template #QTY="slotScope">
                <SlTableInput
                  :value="getInputValue(slotScope)"
                  :foreground="getQtyCellForeground(slotScope)"
                  :prepend-label="getInputLabel(slotScope)"
                  :disabled="!$sl_tabEditable || !!getInputLabel(slotScope)"
                  @submit="(val) => handleCellSubmit({ val, slotScope })"
                />
              </template>
              <template #PURCH_VALUE="slotScope">
                <SlTableInput
                  :text-value="getPurchaseValue(slotScope)"
                  :prepend-label="getInputLabel(slotScope)"
                  disabled
                />
              </template>
              <template #ARRIVAL_DATE="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getDateValue(slotScope) }}
                </div>
              </template>
              <template #ORDERBY="slotScope">
                <div
                  class="sl-table__cell-text"
                  :class="{
                    'sl-table__cell-text--secondary': isNestedRow(slotScope.row)
                  }"
                >
                  {{ getDateValue(slotScope) }}
                </div>
              </template>
              <template #PALLET_COUNT="slotScope">
                <SlTableInput
                  :text-value="getPurchaseValue(slotScope)"
                  :prepend-label="getInputLabel(slotScope)"
                  disabled
                />
              </template>
              <template #ACTIONS-last="slotScope">
                <SlDropdown
                  v-if="!isNestedRow(slotScope.row)"
                  placement="bottom-end"
                >
                  <template #target>
                    <SlButton
                      variant="tertiary"
                      color="grey"
                      size="xs"
                      :disabled="!$sl_tabEditable"
                      icon
                    >
                      <icon
                        data="@icons/vertical-dots.svg"
                        class="size-20 color-grey"
                      />
                    </SlButton>
                  </template>
                  <template #content>
                    <SlDropdownOption
                      v-for="action in getAllowedActions(slotScope.cellValue)"
                      :key="action.flag"
                      select-close
                      @select="action.action(slotScope)"
                    >
                      <template #prepend>
                        <icon
                          v-if="action.icon"
                          class="fill-off size-16 stroke-icon--grey"
                          :data="getIcon(action)"
                        />
                      </template>
                      {{ action.label }}
                    </SlDropdownOption>
                  </template>
                </SlDropdown>
              </template>

              <template #no-data>
                <SlNoData>
                  <template #image>
                    <icon
                      :data="noDataMeta.icon"
                      :class="{
                        'illustration-md': !search,
                        'size-48': search,
                        'fill-off': search
                      }"
                    />
                  </template>
                  <template #title>
                    {{ noDataMeta.title }}
                  </template>
                  <template #text>
                    {{ noDataMeta.text }}
                  </template>
                </SlNoData>
              </template>
            </SlTable>
          </div>
        </SlTabContent>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfigurationModal from '@/components/Modals/PlannedOrders/ConfigurationModal';
import ErrorsNotifyModal from '@/components/Modals/Shared/ErrorsNotifyModal/Index';
import ExternalConfirmModal from '@/components/Modals/Shared/ExternalConfirmModal';
import SplitBetweenSuppliersModal from '@/components/Modals/PlannedOrders/SplitBetweenSuppliersModal';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { access } from '@/mixins/access';
import { shortcut } from '@/mixins/shortcut';
import { exportTypes } from '@/config/report/inventoryReport';
import {
  exportErrorTypes,
  orderTypeByTypeKey,
  plannedOrdersConfig,
  plannedOrdersTypes
} from '@/config/report/inventoryReport/plannedOrders.config';
import modalIds from '@/config/shared/modalsId.config';
import { exportOptions, sourceByRoute } from '@/config/report/inventoryReport/plannedOrders.config';
import { checkboxStates } from '@/config/shared/slTable.config';
import { namespaceByRoute } from '@/config/report';
import { operationInfoTypes } from '@/config/api/operations.config';
import modalsId from '@/config/shared/modalsId.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { preventTabClose, toggleUserInteraction } from '@/helpers/shared/webAPI';
import { toArray } from '@/helpers/utils/toArray';
import { numberToBinary } from '@/helpers/format/workWithNums';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { formatNumber } from '@/helpers/format/formatNumber';
import { getTooltip } from '@/helpers/shared/tooltip';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'PlannedOrders',
  components: {
    ErrorsNotifyModal,
    ConfigurationModal,
    ExternalConfirmModal,
    SplitBetweenSuppliersModal
  },
  mixins: [modal, fileSaver, tableConfig, access, shortcut],
  data() {
    return {
      DEFAULT_FILTER_ID,
      exportTypes,
      modalIds,
      isRedirect: false,
      loading: false,
      ordersCreating: false,
      checkboxKey: 'ORDER_ENABLED',
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Suppliers.Split'),
          icon: 'pen',
          action: (slotScope) => this.showSplitSuppliersModal(slotScope)
        }
      ],
      getTooltip,
      tabsConfig: plannedOrdersConfig(this)
    };
  },
  computed: {
    ...mapState({
      ordersFilters: (state) => state.inventoryReport.plannedOrders.filters,
      availableTabs: (state) => state.inventoryReport.plannedOrders.available_tabs,
      canCreate: (state) => state.inventoryReport.plannedOrders.can_create,
      tab: (state) => state.inventoryReport.plannedOrders.tab,
      isOverdated: state => state.project.project?.isOverdated
    }),
    ...mapGetters({
      ordersByTab: 'inventoryReport/plannedOrders/ordersByTab',
      commonParams: 'inventoryReport/plannedOrders/getCommonParams',
      isEdited: 'inventoryReport/plannedOrders/isEdited'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    activeFilterId() {
      return this.$store.state[this.reportNamespace]?.active_filter_id;
    },
    activeFilter() {
      return this.$store.getters[`${this.reportNamespace}/activeFilter`];
    },
    activeFilterName() {
      return this.activeFilter?.name || '';
    },
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.setTab(value);
      }
    },
    tabs() {
      if (this.availableTabs.length > 1) {
        return this.tabsConfig.filter(tab => {
          const isAllOrders = tab.value === plannedOrdersTypes.ANY;
          const isAvailable = this.availableTabs.includes(tab.value);

          return isAllOrders || isAvailable;
        });
      }

      return toArray(this.tabsConfig[0]);
    },
    currentTabData() {
      return this.ordersByTab(this.tabModel);
    },
    tableConfig() {
      return this.currentTabData.tableConfig;
    },
    // table
    table() {
      return this.currentTabData?.table || {};
    },
    tableMetaInfo() {
      return this.table?.metadata?.calculatedValues;
    },
    checkboxHeader() {
      return this.table?.metadata?.checkbox;
    },
    totalSelected() {
      return this.table?.metadata?.totalSelected;
    },
    tableHeaders() {
      return this.table.headers || [];
    },
    showColumnsOptions() {
      return this.table.metadata?.availableCols?.filter(header => header.key !== this.checkboxKey) || [];
    },
    tableRows() {
      return this.table.rows || [];
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    currentPage() {
      return this.table.currentPage;
    },
    sourceData() {
      return sourceByRoute[this.$sl_routeName];
    },
    exportOptions() {
      return exportOptions(this);
    },
    hasOrders() {
      return this.totalRowsCount > 0 && this.checkboxHeader !== checkboxStates.UNCHECKED;
    },
    isIndeterminate() {
      return this.checkboxHeader === checkboxStates.MIXED;
    },
    headerCheckboxValue() {
      return this.checkboxHeader !== checkboxStates.UNCHECKED;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: ''
      };
    }
  },
  watch: {
    tabModel() {
      if (!this.currentTabData.isLoaded) {
        this.fetchPlannedOrders();
      }
    },
    isEdited(val) {
      preventTabClose(val);
    },
    isOverdated(val) {
      if (val) {
        this.handleBack();
      }
    }
  },
  async beforeMount() {
    await this.fetchPlannedOrders();

    if (this.isOverdated) {
      this.isRedirect = true;
      this.handleBack();
    }
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchOrders: 'inventoryReport/plannedOrders/fetchOrders',
      togglePlannedOrdersChildNodes: 'inventoryReport/plannedOrders/togglePlannedOrdersChildNodes',
      submitCell: 'inventoryReport/plannedOrders/submitCell',
      selectRow: 'inventoryReport/plannedOrders/selectRow',
      exportToCsv: 'inventoryReport/plannedOrders/exportToCsv',
      exportToXlsx: 'inventoryReport/plannedOrders/exportToXlsx',
      saveOrders: 'inventoryReport/plannedOrders/saveOrders',
      createOrders: 'inventoryReport/plannedOrders/createOrders',
      updateOverrides: 'inventoryReport/plannedOrders/updateOverrides',
      saveOverrides: 'inventoryReport/plannedOrders/saveOverrides',
      discardOverrides: 'inventoryReport/plannedOrders/discardOverrides',
      resetState: 'inventoryReport/plannedOrders/resetState',
      setTab: 'inventoryReport/plannedOrders/setTab',
      resetIsTabsLoaded: 'inventoryReport/plannedOrders/resetIsTabsLoaded',
      updateFilters: 'inventoryReport/plannedOrders/updateFilters',
      updateVisibleColumns: 'inventoryReport/plannedOrders/updateVisibleColumns',
      updateTableConfig: 'inventoryReport/plannedOrders/updateTableConfig'
    }),
    async fetchPlannedOrders(isUpdate = false) {
      try {
        if (!isUpdate) {
          this.loading = true;
        }

        await this.fetchOrders(true);
      } finally {
        this.loading = false;
      }
    },
    // filters
    async handleCloseFilter() {
      await this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, DEFAULT_FILTER_ID);

      this.fetchPlannedOrders();
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchPlannedOrders();
    },
    isRowExpanded({ cellValue }) {
      return cellValue.expand;
    },
    toggleRow(scope) {
      this.togglePlannedOrdersChildNodes({
        id: scope.row.entryId,
        expand: !this.isRowExpanded(scope)
      });
    },
    getIcon({ icon }) {
      return require(`@icons/${icon}.svg`);
    },
    // filter parse
    filtersArrToNum(filters = []) {
      const binaryString = filters.reduce((str, item) => {
        str = +item + str;

        return str;
      }, '');

      if (!binaryString) {
        return;
      }

      return parseInt(binaryString, 2);
    },
    filtersNumToArr() {
      return numberToBinary(this.ordersFilters)?.split('').reduce((arr, str) => {
        arr.unshift(+str);

        return arr;
      }, []);
    },
    getAllowedActions(cellValue) {
      return this.actionsConfig.filter(action => cellValue.actionsFlags & 1 << action.flag);
    },
    showSplitSuppliersModal(slotScope) {
      this.showModal(modalIds.SPLIT_BETWEEN_SUPPLIERS, {
        entryId: slotScope.row.entryId
      });
    },
    // route leave
    handleBack() {
      this.$router.push({
        name: this.sourceData.route
      });
    },
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: () => {
          this.isRedirect = true;

          this.discardOverrides();

          next();
        },
        confirmCallback: async() => {
          await this.handleSaveChanges();

          this.isRedirect = true;

          next();
        }
      });
    },
    // export
    handleExportToCsv() {
      this.saveFile(
        this.exportToCsv.bind(this, { tab: this.tabModel }),
        {
          errorTitle: this.$t('PlannedOrders.Ui.btExportToCsv')
        }
      );
    },
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx.bind(this, { tab: this.tabModel }));
    },
    handleExport(value) {
      ampli.plannedOrdersExported({
        exportFormat: value
      });

      if (value === exportTypes.CSV) {
        return this.handleExportToCsv();
      }

      if (value === exportTypes.XLSX) {
        return this.handleExportToXlsx();
      }
    },
    // configuration
    openConfigurationModal() {
      this.showModal(modalIds.PLANNED_ORDERS_CONFIGURATION, {
        filters: this.filtersNumToArr(),
        applyCallback: async(filters) => {
          this.page = 1;

          this.updateFilters(this.filtersArrToNum(filters));
          this.resetIsTabsLoaded();
          await this.saveOrders(this.filtersNumToArr());
        }
      });
    },
    isVisibleCol(colKey) {
      return Boolean(this.tableHeaders.find(header => header.key === colKey));
    },
    toggleVisibleCols(key, state) {
      this.updateVisibleColumns({
        state,
        key
      });
    },
    // row selection
    isRowSelected(row) {
      return row[this.checkboxKey]?.val;
    },
    handleSelectRowsAll(checked) {
      this.updateOverrides({
        [this.checkboxKey]: checked
      });
    },
    handleSelectRows(checked, scope) {
      this.selectRow({
        value: checked,
        tab: this.tabModel,
        rowId: scope.rowId,
        cellKey: this.checkboxKey
      });

      this.updateOverrides({
        id: scope.row.entryId,
        [this.checkboxKey]: checked
      });
    },
    // table configuration
    isNestedRow(row) {
      return row.supplierId;
    },
    getDateValue(scope) {
      return dateByLocaleKey(scope.cellValue.val, localeDateKeys.YMD) || '';
    },
    getPurchaseValue({ rowId, cellValue }) {
      const rowIndex = this.tableRows.findIndex(row => row.id === rowId);
      const currency = this.tableRows[rowIndex]?._currency || '';
      const value = formatNumber(cellValue.val);

      if (currency) {
        return `${currency} ${value}`;
      }

      return value;
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    getInputValue(scope) {
      return this.checkCellValue(scope.cellValue?.val) ?? this.checkCellValue(scope.cellValue?.auto) ?? '';
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val || val === 0) {
        return 'over';
      }

      return '';
    },
    getQtyCellForeground(scope) {
      if (this.getInputLabel(scope)) {
        return '';
      }

      return this.getInputForeground(scope);
    },
    getInputLabel(scope) {
      return scope.cellValue?.label;
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchPlannedOrders();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchPlannedOrders();
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: 'planned_orders',
            ...this.commonParams,
            orderType: orderTypeByTypeKey[this.tabModel]
          }
        });
      } finally {
        this.loading = false;
      }
    },
    formatCellValue(val) {
      if (val === null) {
        return val;
      }

      return +val;
    },
    handleCellSubmit({ val, slotScope }) {
      this.submitCell({
        value: this.formatCellValue(val),
        tab: this.tabModel,
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey
      });

      this.updateOverrides({
        id: slotScope.row.entryId,
        supplierId: slotScope.row.supplierId,
        [slotScope.cellKey]: this.formatCellValue(val)
      });
    },
    // actions
    handleDiscardChanges() {
      if (this.isEdited) {
        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
          text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
          icon: 'style_save_double',
          confirmText: this.$t('Web.Collections.Discard'),
          confirmCallback: this.discardOverrides
        });
      }
    },
    async handleSaveChanges() {
      await Promise.allSettled([
        this.saveOrders(this.filtersNumToArr(this.ordersFilters)),
        this.saveOverrides()
      ]);

      this.$notify({
        type: 'success',
        title: this.$t('Common.Save'),
        text: this.$t('Web.Notification.TitleSavedSuccess')
      });
    },
    parseExportErrors(issues) {
      if (!issues) {
        return null;
      }

      return toArray(issues).reduce((acc, issue) => {
        const parsedIssue = this.parseExportError(issue);

        if (parsedIssue) {
          acc.push(parsedIssue);
        }

        return acc;
      }, []);
    },
    parseExportError(issue) {
      const { type, ...data } = issue;

      if ([exportErrorTypes.ERROR, exportErrorTypes.INFO_MSG].includes(type)) {
        const notificationType = type === exportErrorTypes.ERROR ? 'error' : 'warn';
        const notificationText = this.$t(data?.label?.loccode) || data?.label?.fallback || data?.message;

        return notificationText && this.$notify({
          type: notificationType,
          text: notificationText,
          duration: 10000
        });
      }

      if (type === exportErrorTypes.CONNECTOR_ISSUE) {
        return {
          title: this.$t(data?.label?.loccode) || data?.label?.fallback || data?.message,
          table: data?.table
        };
      }

      if (type === exportErrorTypes.QB_ISSUE) {
        return {
          title: this.$t('QbIssues.Ui.:Caption'),
          table: data?.table
        };
      }

      return null;
    },
    handleExportErrors(e) {
      this.$store.dispatch('user/logout', {
        e,
        from: 'plannedOrders/handleCreate'
      });

      const exportedOrdersCount = e?.metainfo?.exportedLines;

      if (exportedOrdersCount > 0) {
        this.$notify({
          type: 'success',
          title: this.$t('Web.PlannedOrders.NotifyTitlePartialExport'),
          text: this.$t('PlannedOrders.PartiallyExported')
        });
      } else {
        this.$notify({
          type: 'error',
          text: this.$t('PlannedOrders.NothingExported'),
          duration: 10000
        });
      }

      const errorsData = this.parseExportErrors(e?.metainfo?.issues);

      if (!errorsData?.length) {
        return;
      }

      this.showModal(modalIds.ERRORS_NOTIFY, {
        title: this.$t('ExportIssues.Ui.lbIssue'),
        errorsData
      });
    },
    async handleCreate() {
      try {
        this.ordersCreating = true;
        toggleUserInteraction(true);

        await this.$store.dispatch('operations/subscribe', {
          subscriber: async() => this.createOrders(),
          paused: ({ operationData, type }) => {
            if (operationData.message && type === operationInfoTypes.AWAIT_FOR_ACTION) {
              this.showModal(modalsId.EXTERNAL_CONFIRM, {
                message: operationData.message
              });
            }
          }
        });

        this.$notify({
          type: 'success',
          title: this.$t('Main.Ui.acExportPeriodicToErp'),
          text: this.$t('PlannedOrders.Ok')
        });

        ampli.plannedOrdersCreateOrders({
          plannedOrdersSubtab: this.tabModel
        });
      } catch (e) {
        this.handleExportErrors(e);
      } finally {
        this.hideModal(modalsId.EXTERNAL_CONFIRM);
        toggleUserInteraction(false);
        this.ordersCreating = false;
      }
    },
    checkCellValue(value) {
      return (value || value === 0) ? value : null;
    },
    handleContextMenu(callback) {
      callback({
        updateCallback: this.handleCellSubmit,
        showMiddleware: ({ cellValue }) => !cellValue.label
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_mixins.scss";

.content-block {
  height: calc(100% - 16px) !important;

  .meta-info {
    display: flex;
    align-items: center;
    column-gap: 20px;
    width: fit-content;
    margin-bottom: 12px;
    padding: 5px 12px;

    background: $grey-10;
    border-radius: $border-radius-sm;

    .meta-info__item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .meta-info__label {
      color: $grey-80;
      @include body-1;
    }

    .meta-info__value {
      color: $grey-80;
      @include body-1;
    }
  }

  .subpage__table {
    height: calc(100% - 44px);

    &-wrapper {
      height: calc(100% - 92px);
    }
  }

  ::v-deep {
    .sl-tab-content {
      display: flex;
      flex-direction: column;
    }

    .mr-6 {
      margin-right: 6px;
    }
  }
}
</style>
